export default [
  {
    title: 'Dashboard',
    route: 'check-in',
    icon: 'HomeIcon',
  },
  {
    title: 'Projects',
    route: 'projects',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Driver Projects',
    route: 'driverprojects',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Help',
    route: 'help',
    icon: 'HelpCircleIcon',
  },
]

// export default [
//   {
//     title: 'Dashboards',
//     route: 'home',
//     icon: 'HomeIcon',
//   },
//   {
//     title: 'Projects',
//     route: 'second-page',
//     icon: 'FileIcon',
//   },
// ]
